import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Modal from "../modal/Modal.jsx";
import axios from "../../api/axios";
import { SlOptions } from "react-icons/sl";




export default function TableComponent({ data, confirmDisableProperty, fetchUpdatedProperties, lifeTimeFree }) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const accessToken = useSelector((store) => store?.adminlogin?.adminData[0]);
  const [details, setDetails] = useState([]);
  const [dotedoption, setDotedoption] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [manuType, setMenutype] = useState(false);
  const [propName, setPropname] = useState(false);
  // const [lifetimefree, setlifetimefree] = useState(false);
  const [menu, setMenu] = useState([]);


  const dropdownRef = useRef(null);

  const handleDisableClick = async (PropertyNo, isActive) => {
    setDotedoption(false);
    await confirmDisableProperty(PropertyNo, isActive);
    await fetchUpdatedProperties();
  };

  const handelLifeTimeFree = async (PropertyNo, expireAt) => {
    setDotedoption(false);
    lifeTimeFree(PropertyNo, expireAt)
    await fetchUpdatedProperties();
  }

  const handleViewimage = async (PropertyNo) => {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const responseMenu = await axios.get(`/api/menu/image/${PropertyNo}`, options);
      console.log(responseMenu.data.menu);
      setMenu(responseMenu.data.menu);
      setMenutype(responseMenu.data.menuType);
      setPropname(responseMenu.data.PropName)
      //setIsModalOpen(true);
      openModal();
      setDotedoption(false);

    } catch (error) {
      if (error.response && error.response.status === 404) {
        setDotedoption(false);
        alert("No Image Menu Found for this property.");
      } else {
        console.error("Error fetching menu data:", error);
        alert("An error occurred while fetching the menu. Please try again.");
      }
    }
  }

  const handleViewMenu = async (PropertyNo) => {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const responseMenu = await axios.get(`/api/menu/${PropertyNo}`, options);
      // console.log(responseMenu.data.menu);
      // setDotedoption(false);
      // setMenu(responseMenu.data.menu);
      // //setIsModalOpen(true);
      // openModal();

      if (responseMenu.data.menu && responseMenu.data.menu.length > 0) {
        console.log(responseMenu.data.menu);
        setDotedoption(false);
        setMenutype(responseMenu.data.menuType);
        setMenu(responseMenu.data.menu);
        openModal();
      }

    } catch (error) {
      if (error.response && error.response.status === 404) {
        setDotedoption(false);
        alert("No Menu Found for this property.");
      } else {
        console.error("Error fetching menu data:", error);
        alert("An error occurred while fetching the menu. Please try again.");
      }
    }
  }

  const handleOpenModal = async (PropertyNo) => {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await axios.get(
        `/api/admin/property/propertyDetails/${PropertyNo}`,
        options
      );
      console.log(response.data);
      const data = Array.isArray(response.data)
        ? response.data[0]
        : response.data;
      setDetails(data);
      setIsModalOpen(true);
      setDotedoption(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDetails([]);
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleToggleDropdown = () => {
    setDotedoption(!dotedoption);

  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDotedoption(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <td className="whitespace-normal px-4 py-3 text-center text-gray-700">
        {data?.PropName}
      </td>
      <td className="whitespace-normal px-4 py-3 text-center text-gray-700">
        {data?.PropType}
      </td>
      <td className="whitespace-normal px-4 py-3 text-center text-gray-700">
        {data?.CustomerName}
      </td>
      <td className="whitespace-normal px-4 py-3 text-center text-gray-700">
        {data?.PropPhone}
      </td>
      <td className="whitespace-normal px-4 py-3 text-center text-gray-700">
        {data?.PropEmail}
      </td>
      <td className="whitespace-normal px-4 py-3 text-center text-gray-700">
        {data?.PropState}
      </td>
      <td className="whitespace-normal px-4 py-3 text-center text-gray-700">
        {data?.propertyIsActive === 1 ? "Active" : "Inactive"}
      </td>
      <td className="whitespace-normal px-4 py-3 text-center text-gray-700">

        {data?.expired_at && new Date(data?.expired_at) < new Date() ? "Expired" : data?.title === "life_time_free" ? "Our Software User (Free Plan)" : data?.title}

      </td>
      <td className="whitespace-nowrap px-4 py-3 text-center text-gray-700">
        <div className="flex gap-2">
          <button
            className="bg-[#800080] text-white px-2 py-2 rounded "
            onClick={() => handleOpenModal(data.PropertyNo)}
          >
            View
          </button>
          <div className="relative" ref={dropdownRef}>
            <button
              className="px-2 py-2 rounded h-full bg-[#eaecef]"
              onClick={handleToggleDropdown}
            >
              <SlOptions />
            </button>
            {dotedoption && (
              <div className="absolute z-10 right-0 top-full mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-lg">
                <ul>
                  <li className=" hover:bg-gray-100 cursor-pointer">
                    <button
                      className="p-2 w-full"
                      type="button"
                      onClick={() => handelLifeTimeFree(data.PropertyNo, data.expired_at)}
                    >

                      {data?.expired_at !== null ? "Enable Free Plan" : "Disable Free Plan"}

                    </button>
                  </li>
                  {/* {lifetimefree && (
                  <Modal onClose={() => setlifetimefree(false)}>
                    <div>
                      <h3>lifetimefree</h3>
                    </div>
                  </Modal>
                )} */}
                  {/* <li className="p-2 hover:bg-gray-100 cursor-pointer">Delete</li> */}
                  <li className=" hover:bg-gray-100 cursor-pointer">
                    <button
                      className="p-2 w-full"
                      type="button"
                      onClick={() => handleDisableClick(data.PropertyNo, data.propertyIsActive)}
                    >
                      {data?.propertyIsActive === 1
                        ? "Disable Property"
                        : "Enable Property"}
                    </button>
                  </li>
                  <li className="hover:bg-gray-100 cursor-pointer">
                    <button
                      type="button"
                      className="w-full p-2"
                      onClick={() => handleViewimage(data.PropertyNo)}
                    >
                      View Image Menu
                    </button>
                  </li>
                  <li className="hover:bg-gray-100 cursor-pointer">
                    <button
                      type="button"
                      className="w-full p-2"
                      onClick={() => handleViewMenu(data.PropertyNo)}
                    >
                      View Text Menu
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </td>
      {isModalOpen && details && (
        <Modal onClose={handleCloseModal}>
          <h2 className="text-center text-[#7e007e] text-3xl mb-6 mt-2 font-[700]">
            Property Details
          </h2>
          <div className="w-full ">
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Property No:</p>
              <p>{details.PropertyNo}</p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Customer Name:</p>
              <p>{details.CustomerName}</p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Property Name:</p>
              <p>{details.PropName}</p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Address:</p>
              <p>
                {details.PropAddress}, {details.PropState},{" "}
                {details.PropCountry}
              </p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Email:</p>
              <p>{details.PropEmail}</p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Phone:</p>
              <p>{details.PropPhone}</p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Type:</p>
              <p>{details.PropType}</p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Menu Type:</p>
              <p>{details.MenuType}</p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Last Date Modal Displayed:</p>
              <p>
                {" "}
                {new Date(details.LastDateModalDisplayed).toLocaleString()}
              </p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Plan :</p>
              <p>
                {details.title}({details.type})
              </p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Amount:</p>
              <p>{details.amount}</p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Start Date:</p>
              <p> {new Date(details.start_date).toLocaleString()}</p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Expiry Date:</p>
              <p>{new Date(details.expired_at).toLocaleString()}</p>
            </div>
            <div className="flex mt-2 justify-between font-[500] text-[#6f7178] ">
              <p>Status:</p>
              <p>{details.is_active}</p>
            </div>
          </div>
        </Modal>
      )}
      {modalIsOpen && (
        <Modal onClose={closeModal}>
          {manuType === "Text" ? (
            <>
              <h2 className="text-center text-[#7e007e] text-3xl mb-6 mt-2 font-[700]">
                {propName} Text Menu
              </h2>
              {menu.length === 0 ? (
                // Render "No menu items available" if the menu is empty
                <p className="text-center font-[500] text-[#6f7178]">
                  No menu items available.
                </p>
              ) : (
                // Render the menu items if available
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 m-4">
                  {menu.map((category, index) => (
                    <div key={index} className="bg-white shadow-md p-4 rounded mb-4">
                      <h2 className="text-xl font-semibold bg-purple-100 pl-4 py-2 rounded">
                        {category?.category}
                      </h2>
                      {category?.note !== "NONE" && (
                        <p className="text-gray-600 text-xs">{category?.note}</p>
                      )}
                      <div className="mt-2 space-y-2">
                        {category?.subcategories?.map((subcategory, subIndex) => (
                          <div key={subIndex} className="border-l-2 pl-4">
                            <h3 className="text-lg font-semibold">{subcategory?.subcategory}</h3>
                            {subcategory?.NoteOnItem !== "NONE" && (
                              <p className="text-gray-500 text-xs">{subcategory?.NoteOnItem}</p>
                            )}
                            {subcategory?.products?.length > 0 ? (
                              <ul className="list-disc list-inside mt-2 space-y-1">
                                {subcategory?.products?.map((product, prodIndex) => (
                                  <li key={prodIndex} className="flex justify-between text-gray-600">
                                    <span className="font-semibold">
                                      {product?.ProductName}
                                      {product?.NoteOnProduct !== "NONE" && (
                                        <p className="text-gray-400 text-xs">
                                          {product?.NoteOnProduct}
                                        </p>
                                      )}
                                    </span>{" "}
                                    <span className="font-semibold">₹ {product?.UnitPrice}</span>
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <>
              {/* <div className="content mx-6 mt-0 mb-6 md:mt-6"> */}
              <h2 className="text-center text-[#7e007e] text-3xl mb-6 mt-2 font-[700]">
                {propName} Image Menu
              </h2>
              {menu.map((item, index) => (
                <div key={index} className="relative w-full box mb-4">
                  <img
                    src={item.imageUrl}
                    className="w-full h-full object-cover"
                    alt={item.text || "Image"}
                  />
                  <h1>{item.PropName}</h1>
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white">
                    <h2 className="text-center text-white text-2xl capitalize py-1">
                      {item.text}
                    </h2>
                  </div>
                </div>
              ))}
              {/* </div> */}
            </>
          )}
        </Modal>
      )}
    </>
  );
}
